<jacidi-admin-layout>
  <ng-container sidebar>
    <jacidi-sidebar [sidebardItems]="routes" />
  </ng-container>
  <ng-container header>
    <jacidi-admin-header>
      <ng-template appTemplate="logo">
        <img class="logo" src="logo.svg" alt="" />
      </ng-template>
      <ng-template appTemplate="actions">
        <app-restaurant-selector></app-restaurant-selector>
      </ng-template>
      <ng-template appTemplate="profile">
        <jacidi-apps-selector [apps]="apps" />
        <jacidi-profile [user]="user()" (onLogout)="logout()" buttonLabel="LIB.PROFILE.LOG_OUT">
          <ng-container lang-dropdown>
            <p-dropdown
              styleClass="primary"
              optionLabel="name"
              [options]="languages"
              [(ngModel)]="selectedLan"
              (onChange)="changeLan($event.value)">
              <ng-template pTemplate="selectedItem">
                {{ selectedLan!.name | translate }}
              </ng-template>
              <ng-template let-lan pTemplate="item">
                {{ lan.name | translate }}
              </ng-template>
            </p-dropdown>
          </ng-container>
        </jacidi-profile>
      </ng-template>
    </jacidi-admin-header>
  </ng-container>
  <ng-container content>
    <router-outlet></router-outlet>
  </ng-container>
</jacidi-admin-layout>

<p-toast>
  <ng-template let-message pTemplate="headless">
    <div style="margin: 14px"> {{ 'GENERAL.ERROR_PDF' | translate }}: {{ message.data }} </div>
  </ng-template>
</p-toast>

@if (pdfs().length) {
  <section class="pdfs">
    @for (pdf of pdfs(); track pdf.name) {
      <article class="pdf">
        <span>{{ 'GENERAL.GENERATING_PDF' | translate }}: {{ pdf.name }}</span>
        <p-progressBar mode="indeterminate" [style]="{ height: '6px' }" color="var(--text-primary-color)" />
      </article>
    }
  </section>
}
