import { ChangeDetectionStrategy, Component, DestroyRef, inject, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ProgressBarModule } from 'primeng/progressbar';

import {
  TemplateService,
  TemplateDirective,
  JacidiAdminLayoutComponent,
  JacidiSideBarComponent,
  JacidiAdminHeaderComponent,
  JacidiAppsSelectorComponent,
  JacidiProfileComponent,
  SidebarItem,
} from '@components/jacidi-components-lib';

import { AVAILABLE_APPS } from '../../constants/eroom.consts';
import { SessionService } from '../../services/session.service';
import { ROOT_ROUTES } from '../../../app.routes';
import { RestaurantSelectorComponent } from '../../../shared/components/restaurant-selector/restaurant-selector.component';
import { Pdf, PdfService } from '../../services/pdf.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

type Lan = {
  name: string;
  value: string;
};

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    FormsModule,

    TemplateDirective,
    JacidiAdminLayoutComponent,
    JacidiSideBarComponent,
    JacidiAdminHeaderComponent,
    JacidiAppsSelectorComponent,
    JacidiProfileComponent,

    RestaurantSelectorComponent,

    TranslateModule,
    DropdownModule,
    ToastModule,
    ProgressBarModule,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TemplateService, MessageService],
})
export class LayoutComponent {
  private readonly _sessionService = inject(SessionService);
  private readonly _translateService = inject(TranslateService);
  private readonly messageService = inject(MessageService);
  private readonly pdfService = inject(PdfService);
  private readonly destroyRef = inject(DestroyRef);

  apps = AVAILABLE_APPS;
  user = this._sessionService.user;
  appRoutes = ROOT_ROUTES[0].children;
  routes: SidebarItem[] = [];

  languages: Lan[] = [
    { name: 'GENERAL.ENGLISH', value: 'en' },
    { name: 'GENERAL.SPANISH', value: 'es' },
  ];

  selectedLan: Lan | undefined = this.languages.find((lan) => lan.value == this._translateService.getDefaultLang());

  pdfs = signal<Pdf[]>([]);

  constructor() {
    this.routes =
      this.appRoutes
        ?.filter((route) => route.path != '' && route.path != '**')
        .map((route) => {
          return {
            name: route.data?.['i18n'] ? route.data?.['i18n'] : '',
            i18n: route.data?.['i18n'] ? route.data?.['i18n'] : '',
            icon: route.data ? route.data['icon'] : 'crm crm-error-warning-alt',
            path: route.path ? route.path : '',
          };
        }) || [];

    this.pdfService.showToast.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (pdf) => {
        if (pdf.show) {
          this.pdfs.update((prev) => [...prev, pdf]);
        } else {
          this.pdfs.update((prev) => prev.filter((p) => p.name != pdf.name));
        }
        if (pdf.error) {
          this.messageService.add({
            severity: 'error',
            data: pdf.name,
            life: 3000,
          });
        }
      },
    });
  }

  logout() {
    this._sessionService.logout();
  }

  changeLan(lan: Lan) {
    this._translateService.use(lan.value);
    localStorage.setItem('lan', lan.value);
  }
}
